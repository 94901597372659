'use client';

import AscentMenu from '@components/ascents/AscentMenu';
import MediaPlayer from '@components/ascents/MediaPlayer';
import TagBadges from '@components/ascents/TagBadges';
import { PointIcon } from '@components/icons';
import getColor from '@lib/mantine/getColor';
import {
  Anchor,
  Avatar,
  Button,
  Card,
  CardSection,
  Group,
  Rating,
  Stack,
  Text,
} from '@mantine/core';
import { IconMapPinFilled, IconUser } from '@tabler/icons-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';

interface Props {
  ascent: Ascent;
}

dayjs.extend(relativeTime);

export default function AscentCard({ ascent }: Props): JSX.Element {
  const tags = [
    ascent.firstAscent,
    ascent.recommended,
    ascent.withKneepad,
    ascent.holdType,
    ascent.steepness,
    ascent.climbType,
    ascent.rating,
    ascent.hard,
    ascent.soft,
  ];

  return (
    <Card py='xl'>
      <CardSection px='md' pb='xs'>
        <Group justify='space-between'>
          <Group>
            <Avatar src={ascent.user.image} alt={ascent.user.username}>
              <IconUser />
            </Avatar>
            <Stack gap={0} justify='center'>
              <Group gap={4}>
                <Anchor
                  fw='bold'
                  m={0}
                  lh={1.25}
                  c='inherit'
                  underline='never'
                  href={`/users/${ascent.user.username}`}
                >
                  {ascent.user.name}
                </Anchor>
                <Text span c='dimmed' size='xs' m={0} lh={1.25}>
                  &bull; {dayjs(ascent.date).from(new Date())}
                </Text>
              </Group>
              <Anchor
                fz='xs'
                c='dimmed'
                underline='never'
                href={`/users/${ascent.user.username}`}
              >
                @{ascent.user.username}
              </Anchor>
            </Stack>
          </Group>
          <AscentMenu ascent={ascent} />
        </Group>
      </CardSection>

      {ascent.media && (
        <CardSection pb='xs'>
          <MediaPlayer src={ascent.media} />
        </CardSection>
      )}

      <CardSection px='md'>
        <Group gap='xs'>
          <Button
            leftSection={<PointIcon color={getColor(ascent.attempts)} />}
            href={`/climbs/${ascent.climb.nameSlug}`}
            c='inherit'
            size='compact-md'
            variant='transparent'
            component={Link}
          >
            {ascent.climb.name}, {ascent.grade}
            {ascent.hard && '+'}
            {ascent.soft && '-'}
          </Button>
          <Button
            leftSection={<IconMapPinFilled color='gray' size='1.15rem' />}
            href={`/crags/${ascent.crag.nameSlug}`}
            c='inherit'
            size='compact-md'
            variant='transparent'
            component={Link}
          >
            {ascent.crag.name}, {ascent.crag.stateCode}
          </Button>
        </Group>
        {ascent.comment && <Text my='xs'>{ascent.comment}</Text>}
        <Group justify='space-between' mt='xs'>
          <Group gap={4}>
            {tags.some(Boolean) && <TagBadges ascent={ascent} />}
          </Group>
          {ascent.rating && <Rating value={ascent.rating} size='xs' readOnly />}
        </Group>
      </CardSection>
    </Card>
  );
}
