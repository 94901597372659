'use client';

import { AspectRatio, Center } from '@mantine/core';
import { IconBrandVimeo, IconBrandYoutubeFilled } from '@tabler/icons-react';
import Image from 'next/image';
import { useState } from 'react';

interface Props {
  src: string;
}

export function YoutubePlayer({ src }: Props) {
  const [player, setPlayer] = useState(false);

  return (
    <AspectRatio ratio={16 / 9} mx='auto'>
      {player ? (
        <iframe
          src={`https://www.youtube.com/embed/${src
            .split('/')
            .pop()}?autoplay=1`}
          title='YouTube video player'
          style={{ border: 0 }}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      ) : (
        <Image
          alt='beta vida'
          src={`https://i.ytimg.com/vi/${src
            .trim()
            .split('?')[0]
            .split('/')
            .pop()}/hqdefault.jpg`}
          fill
        />
      )}
      {!player && (
        <Center onClick={() => setPlayer(true)} style={{ cursor: 'pointer' }}>
          <IconBrandYoutubeFilled
            size={48}
            color='var(--mantine-color-red-7)'
          />
        </Center>
      )}
    </AspectRatio>
  );
}

export function VimeoPlayer({ src }: Props) {
  const [player, setPlayer] = useState(false);

  return (
    <AspectRatio ratio={16 / 9} mx='auto'>
      {player ? (
        <iframe
          src={`https://player.vimeo.com/video/${src.split('/').pop()}`}
          title='Vimeo video player'
          style={{ border: 0 }}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      ) : (
        <Image
          alt='beta vida'
          src={`https://vumbnail.com/${src.split('/').pop()}.jpg`}
          fill
        />
      )}
      {!player && (
        <Center onClick={() => setPlayer(true)} style={{ cursor: 'pointer' }}>
          <IconBrandVimeo size={18} stroke={1.5} color='whitesmoke' />
        </Center>
      )}
    </AspectRatio>
  );
}

export default function MediaPlayer({ src }: Props) {
  let platform;
  if (src.includes('youtu')) {
    platform = 'youtube';
  } else if (src.includes('instagram')) {
    platform = 'instagram';
  } else if (src.includes('vimeo')) {
    platform = 'vimeo';
  } else {
    platform = 'img';
  }

  switch (platform) {
    case 'youtube':
      return <YoutubePlayer src={src} />;
    case 'vimeo':
      return <VimeoPlayer src={src} />;
    case 'instagram':
      return (
        <AspectRatio ratio={16 / 9} mx='auto'>
          <iframe
            src={`https://www.instagram.com/tv/${src
              .split('/?')[0]
              .split('/')
              .pop()}/embed/`}
            title='Instagram video player'
            style={{ border: 0 }}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </AspectRatio>
      );
    default:
      return (
        <AspectRatio ratio={16 / 9} mx='auto'>
          <Image src={src} alt='beta pic' fill />
        </AspectRatio>
      );
  }
}
