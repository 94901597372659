'use client';

import { ActionIcon, Menu, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconDots, IconEdit, IconTrash } from '@tabler/icons-react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useSWRConfig } from 'swr';

export default function AscentMenu({ ascent, size = 'lg' }) {
  const { mutate } = useSWRConfig();
  const { data: session } = useSession();
  const isCurrentUser = session?.user?._id === ascent?.user?._id;

  async function handleDelete() {
    await fetch(`/api/ascents/${ascent._id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ascent),
    });
    await fetch(`/api/climbs/${ascent.climb.nameSlug}?id=${ascent.climb._id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ascent),
    });
    mutate(`/api/ascents`);
  }

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: 'Delete Ascent',
      centered: true,
      children: (
        <Text size='sm'>
          Are you sure you want to delete your ascent? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDelete(),
    });

  if (!isCurrentUser) {
    return null;
  }

  return (
    <Menu shadow='sm'>
      <Menu.Target>
        <ActionIcon size={size}>
          <IconDots size={20} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component={Link}
          href={`/ascents/${ascent._id}`}
          leftSection={<IconEdit size={16} stroke={1.5} />}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          onClick={openDeleteModal}
          leftSection={<IconTrash size={16} stroke={1.5} />}
          color='red.6'
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
