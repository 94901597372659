import { useMantineTheme } from '@mantine/core';

const getColor = (attempts: number): string => {
  const theme = useMantineTheme();

  switch (attempts) {
    case 3:
      return theme.colors.red[7];
    case 2:
      return theme.colors.yellow[7];
    case 1:
      return theme.colors.teal[7];
    case 0:
      return theme.colors.indigo[7];
    default:
      return theme.colors.red[7];
  }
};

export default getColor;
