import { Badge } from '@mantine/core';

export default function TagBadges({ ascent }) {
  return (
    <>
      {ascent.firstAscent && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          FA
        </Badge>
      )}
      {ascent.withKneepad && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          withKneepad
        </Badge>
      )}
      {ascent.holdType && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          {ascent.holdType}
        </Badge>
      )}
      {ascent.steepness && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          {ascent.steepness}
        </Badge>
      )}
      {ascent.climbType && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          {ascent.climbType}
        </Badge>
      )}
      {ascent.recommended && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          recommended
        </Badge>
      )}
      {ascent.hard && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          hard
        </Badge>
      )}
      {ascent.soft && (
        <Badge
          size='xs'
          component='span'
          mr={2}
          style={{ verticalAlign: 'middle' }}
        >
          soft
        </Badge>
      )}
    </>
  );
}
